body {
    background-color: #e9e9e9 !important;
}

.bg {
    background-color: #f0f0f5 !important;
}

nav {
    background-color: #FFF !important;
    box-shadow: 0 4px 25px 0 rgb(168 180 208 / 20%);
}

.nav-link{
    padding-right: 20px !important;
}

.nav-link:hover {
    color: #000 !important;
}

.nav-pills .nav-link.active {
    background-color: #f9f9f9 !important;
    color: #333 !important;
}

hr{
    color: #888 !important;
    text-align: center;
}

.card {
    box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);
}

footer {
    color: #b4b4b8;
    background-color: #e9e9e9 !important;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text-muted {
    color: #CCC !important;
}

.pointer{
    cursor: pointer;
}

@media only screen and (min-width:450px) {
    .logo{
        max-width: 380px;
    }
}
@media only screen and (max-width:450px) {
    .logo{
        max-width: 180px;
    }
}