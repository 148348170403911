.toggle-button {
    display: flex;
    justify-content: right;
    float: right;
}
.no-style {
    text-decoration: none;
    color: unset;
}

.created {
    color: lightslategray;
}
.tooltip-inner {
    max-width: 350px !important; 
}
